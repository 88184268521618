a {
  text-decoration: none;
  color: #333;
}

li {
  list-style: none;
  color: #333;
}

.footer {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0.4) 100%
  ); // Light background color
  padding: 2rem; // Padding of 2rem for top/bottom and left/right
  display: flex;
  flex-direction: column;
  align-items: center; // Center items horizontally
  text-align: center;

  // Contact Info Section
  .contactInfo {
    display: flex;
    flex-wrap: wrap; // Allow wrapping for smaller screens
    justify-content: space-around; // Distribute items evenly
    align-items: center; // Align items to center vertically
    width: 100%; // Take full width
    margin-bottom: 1rem; // Space below the contact info

    .contactGroup {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: #333;
      margin: 0 1rem;

      .contactItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 80px;
        text-decoration: none;

        span {
          font-size: 1.2rem;
          color: #1d88e5;
        }

        .contactIcon {
          width: 80px;
          height: 80px;
          margin-bottom: 0.5rem;
        }

        .phoneIcon {
          padding-top: 10px;
          width: 80px;
          height: 80px;
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  // Social Media Group
  .socialMediaGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    .socialMedia {
      display: flex;
      justify-content: center; // Center social media icons
      gap: 1.2rem; // Increased gap between icons
      flex-wrap: wrap; // Allow icons to wrap to next line on small screens
      width: 100%; // Ensure it takes full width on mobile

      .socialItem {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 70px; // Set a consistent size for each social icon

        .socialIcon {
          width: 70px;
          height: 70px;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1); // Hover effect
          }
        }
      }
    }

    .contactText {
      margin: 0.5rem 0;
      font-weight: bold;
      color: #333;
    }
  }

  // Media Queries for Responsiveness
  @media (max-width: 1055px) {
    .contactGroup {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .contactInfo {
      flex-direction: row; // Arrange in a row on this screen size
      justify-content: space-between; // Space between the items
      row-gap: 3rem;
      .contactGroup {
        margin: 0 1rem; // Horizontal space between contact groups
      }
    }

    // Ensures the envelope and phone are in the upper row
    .socialMediaGroup {
      width: 100%;
      flex-direction: column; // Keep social media in the lower row
      align-items: center;
      margin-top: 1rem;

      .socialMedia {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4rem;
      }
    }
  }

  @media (max-width: 768px) {
    .contactInfo {
      flex-direction: row; // Row for tablet
      align-items: center;
    }
  }

  @media (max-width: 480px) {
    padding: 1rem; // Reduced padding for mobile
    .contactInfo {
      flex-direction: column; // Stack contact info vertically
      .contactGroup {
        margin: 0.9rem 0; // Smaller vertical space on mobile
      }
    }
    .socialMedia {
      flex-direction: column; // Stack social media icons vertically
      gap: 1rem; // Reduce space between social icons
    }
  }
}

.property {
  padding-top: 30px;
}

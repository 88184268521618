.testimonials {
  padding: 40px 30px;
  background-color: transparent;
  text-align: center;

  h2 {
    font-size: 3rem;
  }

  .testimonialList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .testimonialItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-bottom: 30px;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    cursor: pointer; /* Show pointer cursor to indicate clickability */

    &:hover {
      transform: scale(1.05);
      z-index: 10;
    }

    img {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 20px;
      transition: transform 0.3s ease; /* Smooth transition for the image */
    }

    .feedback {
      font-size: 1.2rem;
      color: #333;
      margin-bottom: 15px;
    }

    .name {
      font-size: 1.1rem;
      font-weight: bold;
      color: #b11b1a;
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modalContent {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .modalContent img {
    height: 100%;
    width: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the image fits within the modal without distortion */
  }

  // Responsive Design for Modal
  @media (max-width: 768px) {
    .modalContent img {
      height: 100%; /* Ensure it takes full height on small screens */
      width: auto; /* Maintain aspect ratio */
    }
  }

  // Responsive Design for Testimonials
  @media (max-width: 1024px) {
    .testimonialItem {
      flex-direction: row;
      align-items: flex-start;
      padding: 15px;
      margin-bottom: 20px;

      img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }

      .feedback {
        font-size: 1.1rem;
      }

      .name {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 30px 15px;

    .testimonialItem {
      flex-direction: column;
      text-align: center;
      margin-bottom: 20px;

      img {
        width: 100px;
        height: 100px;
        margin-bottom: 15px;
      }

      .feedback {
        font-size: 1.1rem;
      }

      .name {
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 480px) {
    padding: 20px 10px;

    .testimonialItem {
      margin-bottom: 15px;

      img {
        width: 90px;
        height: 90px;
      }

      .feedback {
        font-size: 1rem;
      }

      .name {
        font-size: 0.9rem;
      }
    }
  }
}

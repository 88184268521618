.wrapper {
  background: url("../assets/about-background.jpg") no-repeat center center
    fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%; /* Use 100% to avoid horizontal overflow caused by scrollbar */
  max-width: 1200px;
  margin: 0 auto;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: none; /* Transparent to allow the AboutPage background to show */
}

// Loader.module.scss

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full-screen loader
}

.spinner {
  border: 8px solid #f3f3f3; /* Light gray background */
  border-top: 8px solid #3498db; /* Blue color for the spinning part */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spinner animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

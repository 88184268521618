/* Default font for the whole page */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
}

/* Apply Dancing Script for Slovak */
html[lang="sk"] body {
  font-family: "Dancing Script", cursive;
}

/* Apply Dancing Script for Ukrainian */
html[lang="ua"] body {
  font-family: "Dancing Script", cursive;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* General styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

p {
  font-family: "Dancing Script", cursive;
  color: #2684be;
}

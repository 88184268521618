.gallery-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the container content */

  .gallery-title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 1rem;
    color: #2684be;
  }

  .gallery-grid {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);

    @media (max-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);

      .gallery-image {
        filter: grayscale(0%) !important;
      }
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(6, 1fr);

      .gallery-image {
        filter: grayscale(0%) !important;
      }
    }
  }

  .gallery-item {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    .gallery-image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      transition: opacity 0.3s ease, filter 0.3s ease;
      cursor: pointer;
      filter: grayscale(100%);
    }

    &:hover .gallery-image {
      filter: grayscale(0%);
    }

    .image-overlay {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      color: #fff;
      text-align: center;
      padding: 8px;
      opacity: 0;
      transition: opacity 0.3s ease;

      .image-title {
        font-size: 1rem;
        font-family: cursive;
      }
    }

    &:hover .image-overlay {
      opacity: 1;
    }
  }

  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer;
  }

  .lightbox-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 15px;
    object-fit: contain;
  }

  .company-info {
    max-width: 100%;
    margin-top: 2rem;
    padding: 20px;
    text-align: center;
    background-color: transparent;
    border-radius: 8px;
    color: #333;

    h3 {
      font-size: 3rem;
      color: #2684be;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.8rem !important;
      line-height: 1.6;
      color: #b11b1a;
    }
  }

  .video-block {
    display: flex;
    align-items: center;
  }

  .priceListLink {
    font-size: 2rem;
    a {
      display: flex;
      align-items: center;
      color: #2684be;
      text-decoration: none;
      i {
        margin-right: 8px; /* Space between the icon and text */
      }

      &:hover {
        color: #b11b1a; /* Hover effect */
      }
    }
  }
}

/* Header Styling */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  height: 180px;
  max-height: 100%;
  object-fit: contain;
}

.nav {
  display: flex;
  gap: 6rem;
  // padding-right: 3rem;
  padding-top: 1rem;
  transition: padding-right 0.3s ease, gap 0.3s ease;
}

.navLink {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 170px;
  background-image: url("../assets/one-ballon-background-removebg-preview.png");
  background-size: cover;
  background-position: center;
  color: #2584be;
  text-align: center;
  line-height: 95px;
  text-decoration: none;
  font-size: 1.7rem;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.navLink:hover {
  transform: scale(1.05);
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .logo {
    height: 160px;
  }
  .nav {
    gap: 3rem;
    padding-top: 2rem;
  }

  .navLink {
    font-size: 1.3rem;
  }
}

/* Mobile Styles */
@media (max-width: 800px) {
  .logo {
    height: 100px;
  }

  .nav {
    display: none;
  }

  .hamburger {
    display: block;
    font-size: 3rem;
    cursor: pointer;
    color: #333;
    padding-right: 2rem;
  }
}

/* Mobile Navigation Menu */
.navOpen {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 70px;
  right: 0;
  background-color: #f8f9fa;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.navOpen .navLink {
  margin: 10px 0;
  padding: 0.5rem 0;
  font-size: 1.2rem;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

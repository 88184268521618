.slider {
  position: relative;
  text-align: center;
  max-width: 100%;
  margin: 30px auto 120px auto;
  padding: 0 20px;
  height: 250px;
}

.slider h2 {
  color: #b11b1a;
  font-size: 3rem;
}

.sliderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  top: 30px;
  overflow: hidden;
}

.imageContainer {
  display: flex;
  transition: transform 1s ease-in-out; /* Smooth transition for carousel effect */
  width: 100%;
}

.slideRow {
  display: flex;
  justify-content: space-around;
  width: 300%; /* Ensures 3 images are visible */
}

.imageWrapper {
  position: relative;
  flex: 1;
  margin: 0 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out; /* Add transition to the wrapper */
}

.slideImage {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out; /* Smooth transition for scale */
}

.imageWrapper:hover .slideImage {
  transform: scale(1.1); /* Slightly increase the size of the image */
}

.caption {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: cursive;
}

.arrowButton {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.arrowButton:hover {
  color: #ffb6b6;
}

.arrowButton:nth-child(1) {
  left: 0;
}

.arrowButton:nth-child(3) {
  right: 0;
}

/* Full-screen Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modalImage {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .slider {
    margin: 20px auto 40px auto;
  }

  .slideImage {
    max-height: 150px;
  }

  .caption {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .slideImage {
    max-height: 120px;
  }
}

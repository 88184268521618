.articles {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  background: transparent;
}

.sectionTitle {
  font-size: 3rem;
  font-family: "Dancing Script", cursive;
  color: #b11b1a;
  margin-bottom: 2rem;
  text-align: center;
}

.article {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 1rem;
  border-radius: 8px;
}

.textContainer {
  flex: 1;
  padding: 1rem;
  text-align: center;
}

.imageContainer {
  flex: 1;
  max-width: 45%;
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

h2 {
  font-size: 2.6rem;
  color: #b11b1a;
}

p {
  font-size: 1.8rem !important;
  color: #333;
}

.imageLeft {
  flex-direction: row-reverse;
}

.imageRight {
  flex-direction: row;
}

.priceListLink a {
  color: #b11b1a;
  font-size: 2rem;
}

.googleMapOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.googleMapOverlay iframe {
  width: 80%;
  height: 80%;
  border: none;
}

.closeOverlay {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .article {
    flex-direction: column;
  }

  .imageContainer {
    max-width: 100%;
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}

.sideMenu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: #fff;
  transform: translateX(100%); /* Initially off-screen */
  transition: transform 0.3s ease-in-out;
  z-index: 10;
}

.sideMenu.open {
  transform: translateX(0); /* Move into view when open */
}

.nav {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 2rem;
  font-size: 1.5rem;
}

.navLink {
  text-decoration: none;
  color: #333;
  transition: color 0.3s ease, transform 0.2s ease;

  &:hover {
    color: #ff6347; /* Change color on hover */
    transform: translateX(10px); /* Slight movement on hover */
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  cursor: pointer;
}

.dropdown {
  position: relative;
  top: -20px;

  .dropdownToggle {
    color: #b11b1a;
    width: 100px;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: bold;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .dropdownMenu {
    position: absolute;
    background-color: transparent; /* Set background to transparent */
    border-radius: 5px;
    list-style: none;
    margin: 0;
    padding: 10px 0;
    z-index: 1000;
    display: block;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }

  .languageButton {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    padding: 5px 15px;
    color: #b11b1a;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    img {
      width: 30px;
      margin-right: 8px;
    }
  }
}

@media (max-width: 800px) {
  .dropdown {
    top: 0;
  }
}

.videoSection {
  padding: 40px 20px;
  background-color: transparent;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #b11b1a;
}

.videoContainer {
  display: grid;
  gap: 20px;
  max-width: 100%;
  margin: 0 auto;

  // Default to two columns
  grid-template-columns: repeat(2, 1fr);

  .instagram-media {
    width: 100%;
    max-width: 100%;
  }
}

// Responsive Adjustments
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  // Switch to one column layout on smaller screens
  .videoContainer {
    grid-template-columns: 1fr;
  }

  .videoContainer .instagram-media {
    width: 100%;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}
